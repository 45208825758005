export const getSocialUrl = (handle: string, network: string) => {
    if (handle) {
        if (network === 'facebook') {
            return `https://facebook.com/${handle.replace('@', '')}`;
        }

        if (network === 'linkedin') {
            return `https://linkedin.com/in/${handle.replace('@', '')}`;
        }

        return `https://twitter.com/${handle.replace('@', '')}`;
    } else {
        return '';
    }
};
export const isLinkExternal = (link: { type: string; }) =>
    link?.type === 'external' || link?.type === 'video' || link?.type === 'download' || link?.type === 'social';

export const getCurrentUrlWithParams = (localeCode: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    const centerValue = queryParams.get('center');

    return (
        `${window.location.origin}${window.location.pathname}?lang=${localeCode}` +
        (centerValue && centerValue !== 'global' ? `&center=${centerValue}` : '')
    );
};
