// Checks if a rich text field is null or has an empty text node
export const isDekNotEmpty = (dek) => {
    return !!(dek && dek?.root?.children[0].children?.length);
};

export const getContentTypeParsers = (related, overrideImage) => {
    const index = related;
    const summary = related?.summary;
    const contentType = index.subtype === 'other' || !index.subtype ? index.contentType : index.subtype;

    return {
        common() {
            return {
                image: overrideImage || summary?.hero?.image,
                label: contentType,
                title: index.title,
                description: summary?.dek || [],
                people: summary?.contributors || [],
                url: index.path,
                syndications: summary?.syndications,
                publishedAt: index.publishedAt,
                publisherName: summary?.publisherName,
            };
        },

        research() {
            if (index.subtype === 'book' || index.subtype === 'report') {
                return {
                    ...this.common(),
                    image: overrideImage || summary?.publicationDetails?.cover || summary?.hero?.image,
                    imageType: index.subtype,
                    mainAuthors: summary?.mainAuthors || [],
                };
            } else {
                return {
                    ...this.common(),
                    mainAuthors: summary?.mainAuthors || [],
                };
            }
        },

        posts() {
            if (index.subtype === 'legacyInTheMedia') {
                return {
                    ...this.common(),
                    label: 'In the Media',
                };
            } else {
                return {
                    ...this.common(),
                };
            }
        },

        article() {
            return {
                ...this.common(),
            };
        },

        events() {
            return {
                ...this.common(),
                dateTime: summary?.eventDate,
                label: summary?.subtype,
                videoPoster: summary?.video?.posterImage,
            };
        },

        podcastEpisodes() {
            return {
                ...this.common(),
                label: 'podcast',
            };
        },

        podcasts() {
            return {
                ...this.podcastEpisodes(),
            };
        },

        pressRelease() {
            return {
                ...this.common(),
                label: 'in the media',
            };
        },

        collections() {
            return {
                ...this.common(),
                images: summary.imageCollage || [],
                label: 'collection',
                title: index.title,
            };
        },

        features() {
            return {
                ...this.common(),
                label: 'feature',
            };
        },

        videos() {
            return {
                ...this.common(),
                label: 'video',
                image: summary.video?.posterImage,
            };
        },

        inTheMedia() {
            return {
                ...this.common(),
                mediaSource: summary?.mediaSource,
                label: 'in the media',
            };
        },

        editions() {
            return {
                ...this.common(),
                label: 'edition',
            };
        },

        eventCollections() {
            return {
                ...this.common(),
                label: 'event collection',
            };
        },

        newsletters() {
            return {
                ...this.common(),
                label: 'newsletter',
            };
        },

        people() {
            return {
                ...this.common(),
                label: 'expert',
            };
        },

        blogs() {
            return {
                ...this.common(),
                label: 'blog',
            };
        },

        blogsLanding() {
            return {
                ...this.common(),
                label: 'blogs',
            };
        },

        centers() {
            return {
                ...this.common(),
                label: 'center',
            };
        },

        directories() {
            return {
                ...this.common(),
                label: 'directory',
            };
        },

        donationPortals() {
            return {
                ...this.common(),
                label: 'donation portal',
            };
        },

        eventsLanding() {
            return {
                ...this.common(),
                label: 'events',
            };
        },

        expertsLanding() {
            return {
                ...this.common(),
                label: 'experts',
            };
        },

        informationals() {
            return {
                ...this.common(),
                label: 'informational',
            };
        },

        podcastLanding() {
            return {
                ...this.common(),
                label: 'podcasts',
            };
        },

        programs() {
            return {
                ...this.common(),
                label: 'program',
            };
        },

        programProjectsLanding() {
            return {
                ...this.common(),
                label: 'programs & projects',
            };
        },

        projects() {
            return {
                ...this.common(),
                label: 'project',
            };
        },

        researchLanding() {
            return {
                ...this.common(),
                label: 'research',
            };
        },

        staff() {
            return {
                ...this.common(),
                label: 'staff',
            };
        },

        regions() {
            return {
                ...this.common(),
                label: 'region',
            };
        },
    };
};

// Combines static content feed items with data from the dynamic feed.
export const getContentFeedItems = (staticFeed = [], dynamicFeed = []) => {
    const dynamic = dynamicFeed?.length ? [...dynamicFeed] : [];
    return staticFeed?.map((item) => {
        if (item.contentItemType === 'dynamic') {
            return dynamic.shift();
        }
        if (item.contentItemType === 'imageHeading') {
            return { ...item.imageHeading };
        }
        return {
            ...item.contentCard.content[0],
            overrideImage: item.contentCard.overrideImage,
        };
    });
};

// Combines programs, projects, and blogs for affiliations
export const getAffiliationContent = (syndications) => {
    const blogs = syndications?.blogs?.filter((blog) => !!blog.reference) || [];
    const affiliatedBlogData = blogs?.map((blog) => {
        return {
            ...blog,
            eyebrow: 'blog',
            image: blog.reference.heroBlog?.heroImage || null,
            mobileImage: blog.reference.heroBlog?.mobileHeroImage || null,
            logoImage: blog.isPrimary ? blog.reference.heroBlog?.logo : null,
        };
    });

    const programs = syndications?.programs?.filter((program) => !!program.reference) || [];
    const affiliatedProgramData = programs?.map((program) => {
        return {
            ...program,
            eyebrow: 'program',
            image: program.reference.heroProgramsProjects?.heroImage || null,
            mobileImage: program.reference.heroProgramsProjects?.mobileHeroImage || null,
            logoImage: program.isPrimary ? program.reference.heroProgramsProjects?.logo : null,
        };
    });

    const projects = syndications?.projects?.filter((project) => !!project.reference) || [];
    const affiliatedProjectsData = projects?.map((project) => {
        return {
            ...project,
            eyebrow: 'project',
            image: project.reference.heroProgramsProjects?.heroImage || null,
            mobileImage: project.reference.heroProgramsProjects?.mobileHeroImage || null,
            logoImage: project.isPrimary ? project.reference.heroProgramsProjects?.logo : null,
        };
    });

    return [...affiliatedBlogData, ...affiliatedProgramData, ...affiliatedProjectsData];
};

// Combines collections and event collections
export const getCollectionContent = (syndications) => {
    const collections = syndications?.collections?.filter((collection) => !!collection.title) || [];
    const affiliatedCollectionData = collections?.map((collection) => {
        return {
            ...collection,
            eyebrow: 'collection',
        };
    });
    const eventCollections = syndications?.eventCollections?.filter((eventCollection) => !!eventCollection.title) || [];
    const affiliatedEventCollectionData = eventCollections?.map((eventCollection) => {
        return {
            ...eventCollection,
            eyebrow: 'event collection',
        };
    });

    return [...affiliatedCollectionData, ...affiliatedEventCollectionData];
};

export const cleanObjectKeys = (obj = {}) => {
    const cleanedObj = {};
    Object.entries(obj).forEach(([key, field]) => {
        const fieldKey = key.split('___').pop();
        if (field instanceof Array) {
            cleanedObj[fieldKey] = field.map((item) => {
                if (item instanceof Object) {
                    return cleanObjectKeys(item);
                }
                return item;
            });
        } else if (field instanceof Object) {
            cleanedObj[fieldKey] = cleanObjectKeys(field);
        } else {
            cleanedObj[fieldKey] = field;
        }
    });
    return cleanedObj;
};

export const getNewsletterFormComponent = (newsletterFormName) => {
    // POST LAUNCH FIXME: this is very brittle, takes down the entire server if the component name is wrong
    // there's got to be a way to do this better
    // two ideas would be to try the errorComponent and also to look for the actual file
    return defineAsyncComponent({
        loader: () =>
            import(`../components/forms/newsletter/${newsletterFormName}.vue`).catch((error) => {
                console.error(`Failed to load component /components/forms/newsletter/${newsletterFormName}:`, error);
            }),
    });
};
